import React from "react"
import PropTypes from "prop-types"

const PostCard = ({ postSlug, postThumb, postTitle, postDesc, postAuthor, postDate, isPrimary }) => (
    <div className="postcard-wrapper">
        <a href={"/post/"+postSlug} className={isPrimary ? "post-card primary-card" : "post-card"}>
            <div className="post-card-thumb">
                <img src={postThumb} alt={postTitle} />
            </div>
            <div className="post-card-info">
                <div className="post-card-title">
                    <h5>{postTitle}</h5>
                </div>
                <div className="post-card-desc">
                    {postDesc}
                </div>
            </div>
            <div className="post-card-footer">
                <span className="post-card-author">
                    {postAuthor}
                </span>
                <span className="post-card-date">
                    {postDate}
                </span>
            </div>
        </a>
    </div>
)

PostCard.defaultProps = {
    postSlug: ``,
    postThumb: ``, 
    postTitle: ``,
    postDesc: ``,
    postAuthor: ``,
    postDate: ``,
    isPrimary: false,
}
  
PostCard.propTypes = {
    postSlug: PropTypes.string.isRequired,
    postThumb: PropTypes.string.isRequired,
    postTitle: PropTypes.string.isRequired,
    postDesc: PropTypes.string.isRequired,
    postAuthor: PropTypes.string.isRequired,
    postDate: PropTypes.string.isRequired,
    isPrimary: PropTypes.bool,
}

export default PostCard
