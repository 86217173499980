import React from "react"
import { useFlexSearch } from "react-use-flexsearch"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PostCard from "../components/postcard"

const PostsPage = ({ data }) => {
  let paramString = ''
  if (typeof window !== "undefined") {
    paramString = window.location.search.substring(1);
  }
  const searchParam = new URLSearchParams(paramString).get("search")
  const showAllResults = searchParam === null

	const results = useFlexSearch(searchParam, data.localSearchPosts.index, data.localSearchPosts.store, {
    suggest: true,
  })
  const posts = showAllResults ? data.allMarkdownRemark.nodes : results
  return (
    <>
      <Layout>
        <SEO title={showAllResults ? 
          "All Articles" 
          : results.length !== 0 ? 
            "Search results for "+searchParam 
            : "No results found"}
        />
        <div className="posts-page content">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {
                  showAllResults ? <h1 className="title">All <span className="t-primary">articles</span></h1>
                : results.length !== 0 ?
                  <h2 className="search-results-title title">Search results for <span className="t-primary">{searchParam}</span></h2>
                  : <h2 className="search-results-title title">No results found for <span className="t-primary">{searchParam}</span></h2>
                }
              </div>
              {showAllResults ? posts.map((post, index) => {
                return (
                  <div className="col-md-6 col-sm-12" key={index.toString()}>
                    <PostCard
                      postSlug={post.frontmatter.slug}
                      postThumb={post.frontmatter.img_url}
                      postTitle={post.frontmatter.title}
                      postDesc={post.frontmatter.description}
                      postAuthor={post.frontmatter.author}
                      postDate={post.frontmatter.date}
                    />
                  </div>
                )
              })
              : posts.map((post, index) => {
                return (
                  <div className="col-md-6 col-sm-12" key={index.toString()}>
                    <PostCard
                      postSlug={post.slug}
                      postThumb={post.img_url}
                      postTitle={post.title}
                      postDesc={post.description}
                      postAuthor={post.author}
                      postDate={post.date}
                    />
                  </div>
                )
              })}
              {
                showAllResults ? <></> :
                <>
                  <div className="all-posts-btn col-12">
                    <Link to="/posts">
                      <button className="btn">View All Articles &gt;&gt;</button>
                    </Link>
                  </div>
                </>
              }

            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default PostsPage;

export const pageQuery = graphql`
{
  allMarkdownRemark(sort: {order: DESC, fields: frontmatter___date}, limit: 500) {
    nodes {
      frontmatter {
        author
        date(formatString: "DD MMMM, YYYY")
        description
        img_url
        slug
        title
      }
    }
  }

  localSearchPosts {
    index
    store
  }
}
`
